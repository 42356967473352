@import "index.scss";
$cardPadding: 16px;
.LoginWrapper {
  min-width: 330px;
  .firebaseui-container {
    font-family: $UIFont;
    .firebaseui-form-actions {
      .firebaseui-id-submit,
      .firebaseui-id-secondary-link {
        font-size: 0 !important;
        text-transform: initial;
        /* Hide text on original button */
      }
      .firebaseui-id-submit::after {
        content: "Submit";
        font-size: 1rem !important;
      }
  
      .firebaseui-id-secondary-link::after {
      content: "Cancel";
      color: $oldKey5;
      font-size: 1rem !important;
    }
    .mdl-textfield,
    .mdl-button,
    label,
    input {
      font-size: ms(0);
    }
  }
  .firebaseui-button.firebaseui-id-secondary-link, .firebaseui-link.firebaseui-id-secondary-link {
      background-color: white;
      color: $oldKey5;
      font-size: ms(0);
      &:hover,
      &:active,
      &:focus {
        background-color: unset !important;
      }
    }
    .firebaseui-card-header {
      display: none;
    }
    .mdl-textfield,
    .mdl-button,
    label,
    input {
      font-family: $UIFont;
      font-size: ms(0);
    }
    .firebaseui-button {
      background-color: $oldKey5;
      box-shadow: none;
      border-radius: $borderRadius;
      &:hover,
      &:active,
      &:focus {
        background-color: $oldKey5 !important;
      }
    }
    .mdl-textfield__label:after,
    .firebaseui-label:after {
      background-color: $oldKey5 !important;
    }
    .firebaseui-resend-container {
      display: none;
      /* TODO probably we'll need to actually re-enable this feature eventually */
    }
  }
  .mdl-card {
    background: clear;
    border-radius: unset;
    margin: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: unset;
  }
  form, .firebaseui-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .mdl-shadow--2dp {
    box-shadow: none;
  }
  .firebaseui-card-header,
  .firebaseui-card-content,
  .firebaseui-card-actions,
  .firebaseui-card-footer {
    padding-left: $cardPadding;
    padding-right: $cardPadding;
  }
  .firebaseui-card-content {
    padding-top: $cardPadding/2;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $grayBG;
  }
  .firebaseui-card-actions {
    border-top: 1px solid $grayBGBorder;
    padding-top: $cardPadding;
    padding-bottom: $cardPadding;
  }
  .firebaseui-card-footer {
    display: none; // TODO: we'll need to re-enable this eventually.
  }
  .firebaseui-text {
    @include margin-top(0.5);
    @include margin-bottom(0.25);
    color: $gray5;
  }
  .firebaseui-text a {
    display: unset;
    color: $oldKey5;
  }
  #firebaseui_container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .mdl-progress.firebaseui-busy-indicator {
    top: 0;
  }
}
