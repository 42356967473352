@import "../styles/index.scss";
@import "./Card.scss";
@import "../index.scss"; // TODO ditch legacy styles

$reviewAreaHeightDesktop: 570px;
$reviewAreaHeightHandhelds: 540px;

.ReviewArea {
  margin-top: 1em;
  margin-bottom: 2em;
  user-select: none;
  background-color: $key00; // background-color: white;
  border-radius: 8px;
  height: $reviewAreaHeightDesktop;
  position: relative;
  overflow: hidden;
  transition: height 1s $expoOut;
  display: flex;
  align-items: center;

  margin-left: -$largeColumnMargin;
  width: calc(100% + 2 *#{$largeColumnMargin});

  @include respond-to(handhelds-or-shorties) {
    margin-top: $handheldColumnMargin;
    margin-bottom: $handheldColumnMargin;
    margin-left: -$handheldColumnMargin;
    width: calc(100% + 2 *#{$handheldColumnMargin});
    height: $reviewAreaHeightHandhelds;
    border-radius: unset;

    font-size: $base; // The mobile review area uses the desktop font size.
  }

  .CardContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.15s linear;
  }

  &.ReviewAreaComplete {
    height: $lineHeight * 2;
  }
}

@media screen and (max-width: 374px) {
  .review_set,
  .StudySessionRoot .StudySessionContents .ReviewArea {
    transform: scale(calc(320 / 375));
    // margin-: calc(100vw * ((320 / 375px) - 1) / 2);
    // margin-right: calc((100vw - 375px) * 375/320/ 2);
    margin-top: calc((#{$reviewAreaHeightHandhelds} * ((320 / 375) - 1)) / 2);
    width: 375px;
    overflow: hidden;
  }
  .review_set {
    // margin-bottom: calc((#{$reviewAreaHeightHandhelds} * ((320 / 375) - 1)) / 2) - $handheldColumnMargin;
    margin-left: calc(
      (375px * ((320 / 375) - 1)) / 2 - #{$handheldColumnMargin}
    );
    margin-bottom: -128px; // Not quite sure why...
  }

  .StudySessionRoot .StudySessionContents .ReviewArea {
    // height: 568px * 375/320;
    margin-bottom: calc(
      (#{$reviewAreaHeightHandhelds} * ((320 / 375) - 1)) / 2
    );
  }
}

.ReviewAreaCompleteSummary,
.ReviewAreaLoadingMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $UIFont;
  @include interfaceCopyStyle;
  line-height: $cardLineHeightFactor;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 32px);
  height: 100%;
  color: $secondaryDarker;
}

.ReviewAreaLoadingMessage {
  // width: 100%;
  text-align: center;
}

.ReviewAreaCompleteGlyph {
  width: 30px;
  height: 24.6px;
  mask: url("/assets/ui/correct.svg");
  mask-size: 100%;
  mask-repeat: no-repeat;
  background-color: $secondaryDarker;
  margin-right: 16px;
  flex-shrink: 0;
}

.BottomControlArea {
  position: absolute;
  left: calc(50% - #{$cardWidth} / 2);
  bottom: 25px;
  width: $cardWidth;
  z-index: 10;
  // height: 60px;

  .ReviewButtonArea {
    display: flex;
    //box-shadow: 0px 0px 10px 10px $key00;
    //background-color: $key00;

    button {
      flex-grow: 1;
      flex-basis: 50%;

      &:first-child {
        @include margin(right, 0.5);
      }
    }
  }

  @include respond-to(handhelds-or-shorties) {
    bottom: $handheldColumnMargin;
    right: $handheldColumnMargin;
    height: unset;
  }
}

.ReviewButtonArea {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.15s linear;

  @include respond-to(handhelds-or-shorties) {
    justify-content: space-between;
    width: 100%;
  }
}

.ReviewAreaProgress {
  display: none;
  top: 0%;
  height: 3px;
  background-color: #d2c9ff;
  transition: width 0.5s $expoOut;
}

// TODO: extract all the study session stuff to StudySession.scss.
.StudySessionRoot {
  background-color: $key00;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  -webkit-overflow-scrolling: touch;
  overflow: scroll;

  .StudySessionContents {
    @include respond-to(handhelds) {
      width: 100vw;
      margin: 0 $handheldColumnMargin;
    }

    @include respond-to(non-handhelds) {
      @include essayBodyColumnLayout;
    }

    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    // This is pretty hacky.
    .DesktopStudySessionProgressContainer {
      @include respond-to(handhelds) {
        display: none;
      }
      height: 0;
      z-index: 100;

      @include hideOnHandhelds;
      position: absolute;
      // below is coupled with SummaryNeue
      top: calc(50vh - #{$newCardHeight} / 2.0 - #{scaleUnit(5)});
      left: calc(50% - (375px/2.0) - #{scaleUnit(0.5)});
      width: 375px;

      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  .ReviewArea {
    overflow: visible;
    margin-left: unset;
    margin-top: 0;
    margin-bottom: 0;
    flex-grow: 1;
    height: 100%;

    @include respond-to(handhelds-or-shorties) {
      width: 100%;
    }
  }

  .ReviewIsShowingAnswer {
    @include respond-to(handhelds-or-shorties) {
      opacity: 0 !important; // HACK
      pointer-events: none;
    }
  }

  .ReviewAreaCompleteSummary {
    // height: unset;
  }

  @include respond-to(handhelds) {
    .ReviewAreaCompleteSummary,
    .ReviewAreaLoadingMessage {
      margin: 0 $handheldColumnMargin;
    }

    .ReviewAreaCompleteGlyph {
      margin-top: 3px;
      // align-self: flex-start;
    }
  }
}
