@import "../index.scss"; // TODO ditch legacy styles
@import "../styles/index.scss";

$cardWidth: 343px;
$cardHeight: 450px;
$newCardHeight: 439px;

$cardAreaHeight: 196px;

// note overridden in ReviewArea.scss by .StudySessionRoot .CardDynamicsContainer
.CardDynamicsContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  will-change: transform;
  opacity: 1;
  overflow-y: scroll;
  padding-top: 24px;
  padding-bottom: 77px;
  transition: transform 1.2s 0ms $expoOut;

  &.Disappearing {
    transition: opacity 80ms linear, transform 350ms $expoOut;
    transform: scale(1.2);
    pointer-events: none;
    --offset: 0px;
  }
}

.Card {
  background: linear-gradient(
    to bottom,
    #fff,
    #{$key00} #{$cardAreaHeight * 2}
  );
  border-radius: $borderRadius;
  box-shadow: 0px 7px 50px #{rgba($primaryExtraDark, 0.05)},
    0px 1px 1px 0.6px #{rgba($primaryExtraDark, 0.1)};
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  position: relative;
  flex-direction: column;
  width: $cardWidth;

  min-height: $newCardHeight;
  max-height: $newCardHeight;

  margin-left: auto;
  margin-right: auto;
  transition: all 150ms ease-out;

  transform: translateY(
    var(--offset)
  ); // set in ReviewArea.tsx. This class should probably "belong" to it.
}

.Prompt {
  flex-basis: $cardAreaHeight;
}

.Answer {
  flex-basis: $cardAreaHeight;
  min-height: 0;
  width: 100%;
  border-top: 1px solid $grayBGBorder;
  box-sizing: border-box;
  background-color: $key00;
}

.Prompt,
.Answer {
  @include padding(top bottom left right, 1);
  transition: all 150ms ease-out;
  overflow: hidden;
}

.Card.WithExplanation {
  &.ExplanationExpanded {
    .Explanation {
      flex-grow: 1;
    }

    max-height: unset;
  }
}

.Prompt,
.Answer,
.Explanation {
  font-family: $UIFont;
  @include study-font-size(0);
  position: relative;

  .Occluded & {
    display: none;
  }

  p {
    line-height: $base * $cardLineHeightFactor;
    margin-bottom: $base * $cardLineHeightFactor * 0.5;
  }

  &.MeasurementOverride {
    width: unset;

    p {
      display: table; // Force contents to expand width.
    }
  }

  &.Shrink1,
  &.Shrink2,
  &.Shrink3,
  &.Explanation {
    $shrunkenFontSize: 16px;
    font-size: $shrunkenFontSize;
    p {
      line-height: $shrunkenFontSize * $cardLineHeightFactor;
      margin-bottom: $shrunkenFontSize * $cardLineHeightFactor * 0.5;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.Shrink2,
  &.Shrink3,
  &.Explanation {
    .katex-display {
      margin-top: 0;
      margin-bottom: 0;
    }

    .katex {
      font-size: 1em;
    }
  }

  &.Shrink3 {
    $shrunkenFontSize: 15px;
    font-size: $shrunkenFontSize;
    p {
      line-height: $shrunkenFontSize * $cardLineHeightFactor;
      margin-bottom: $shrunkenFontSize * $cardLineHeightFactor * 0.25;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  img {
    object-position: center;
    object-fit: contain;
    width: 100%;
    max-height: max-content;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: -16px; // Weird hack to make up for containing <p> line height.
    }

    &[src*="#FloatRight"] {
      float: right;
      object-fit: contain;
      object-position: top;
      margin-left: 12px;
      width: unset;
      max-width: 50%;
      max-height: calc(#{$cardAreaHeight} - 16px * 2);
    }
    &[src*="#FillBox"] {
      max-height: calc(#{$cardAreaHeight} - 16px * 2);
    }

    &[src*="#FullBleed"] {
      margin-left: -32px;
      max-width: unset;
      width: calc(100% + 32px * 2);
    }

    &[src*="#RoundCorners"] {
      border-radius: $borderRadius;
    }

    &[src*="#NoBottomMargin"] {
      margin-bottom: -48px;
      height: unset;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.Cloze {
  display: inline-block;
  width: 3em;
  border-bottom: 1px solid $gray7;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: -4px;
  position: relative;
}

.ClozeContainer,
.InlineMathContainer {
  white-space: nowrap;
}

.ApplicationPromptLabel {
  width: 100%;
  background-color: $key10;
  @include padding(top bottom, 0.25);
  @include padding(left right, 1);
  margin-bottom: -4px;
  position: relative;
  &:before {
    content: "Application prompt";
    color: $primaryExtraDark;
    @include labelStyle;
  }
}

$levelContainerHeight: 47px;
$oldLevelContainerHeight: 58px;

.Explanation {
  width: 100%;
  padding: 8px 16px;
  border-top: 1px solid $grayBGBorder;
  box-sizing: border-box;
  background-color: $key00;
  flex-shrink: 0;

  .ExplanationHeading {
    @include labelStyle;
    color: $primaryDark;
    @include margin(bottom top, 0);
    position: relative;
    padding-left: 16px;

    &::before {
      position: absolute;
      left: 0px;
      top: 3.5px;
      width: 8px;
      height: 10px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 8px solid $primaryDark;
      box-sizing: border-box;
      content: "";

      transition: transform 250ms $expoOut;
    }
    transition: margin-bottom 150ms ease-out;
  }

  &-Expanded {
    .ExplanationHeading {
      &::before {
        transform: rotate(90deg);
      }
      @include margin(bottom, 0.5);
    }
  }
}

.LevelContainer {
  background-color: white;
  border-top: 1px solid $grayBGBorder;
  @include padding(top, 0.25);
  @include padding(bottom, 0.25);
  width: 100%;

  display: flex;
  justify-content: center;
  flex-shrink: 0;

  transition: opacity 0.3s linear 0.1s, transform 0.35s $expoOut 0.1s;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

.LevelContainerHidden {
  opacity: 0;
  display: none;
  transform: translateY(20px);
}

.Level {
  display: flex;
  flex-direction: column;
}

.AnswerCover {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  height: calc(100% - #{$cardAreaHeight});

  width: 100%;
  background-color: $key00;
  border-top: 1px solid rgba(0, 0, 0, 0.02);
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  transition: opacity 0.125s linear 0.05s;
  overflow: hidden;

  .Occluded & {
    display: none;
  }

  .MarqueeContainer {
    mask: radial-gradient(
      circle at 50% 10%,
      white,
      white 20%,
      rgba(255, 255, 255, 0.25) 55%,
      transparent 100%
    );
    @include padding(top, 0.5);
    user-select: none;
  }

  p {
    @include captionStyle;
    white-space: nowrap;
    @include margin(bottom, 0.5);
    width: $cardWidth;

    animation: AnswerCoverMarquee 100s linear infinite running;
    animation-fill-mode: both;

    span {
      display: inline-block;
      @include margin(right, 1);

      color: transparent;
      background-color: rgba($primaryDark, 0.2);

      text-shadow: 0px 1px 0px #{rgba($key00, 0.2)};
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;

      -webkit-font-smoothing: antialiased;
    }
    @keyframes AnswerCoverMarquee {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }

  .NeedsRetryNotice {
    position: absolute;
    bottom: 0;
    background-color: $key00;
    width: 100%;
    height: $levelContainerHeight;
    display: flex;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    // border-top: 1px solid $grayBGBorder;

    align-items: center;
    justify-content: center;

    $noticeColor: rgba($primaryDark, 0.3);

    span {
      @include captionStyle;
      color: $noticeColor;
      margin-bottom: 1px;
    }

    svg {
      @include margin(right, 0.5);
      fill: $noticeColor;
      stroke: $noticeColor;
    }
  }
}

.LoginCard {
  cursor: unset;
  height: $newCardHeight;
}
